



export enum DIGITAL_PRODUCT {
    DOWNLOADS = 'digital_download',
    SOFTWARE_TOOLS = 'business_software_tools',
    MONTHLY_SUBSCRIPTION = 'monthly_subscription'
}



export enum WEBROOT_TEXT {
    MONTHLY = 'Month',
    YEARLY = 'Year',
    MAC_VERSION = '(Mac Version)'
}